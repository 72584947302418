(function(angular){
    "use strict";
    angular.module('ApiUtils.crop.potato.dashboard')
        .factory('PotatoDashboard', PotatoDashboardFactory);

    function PotatoDashboardFactory($resource){
        return $resource('api/v1.0/potato/potato-dashboard/', {}, {
            query: {
                isArray: false
            }
        });
    }

})(angular);