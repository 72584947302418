angular.module('articles_setup_module').directive('articlesMetaSetup', [
    function () {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: '/static/assets/khapps/articles/setup_module/partials/articles_meta_setup.html'
        };
    }]);
angular.module('articles_setup_module').directive('articlesMetaSetupRow', [
    function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                'config': '='
            },
            templateUrl: '/static/assets/khapps/articles/setup_module/partials/articles_meta_setup_row.html',
            controller: function ($scope, ArticlesMetaSetupFactory, $khMessage) {
                $scope.$on('toggleOpen', function () {
                    ArticlesMetaSetupFactory.init($scope.config['factorymodel']).query(function (result) {
                        $scope.items = result;
                    });
                    $scope.editingData = [];
                    $scope.modify = function (tableData) {
                        $scope.editingData[tableData.id] = true;
                    };
                    $scope.cancelAdding = function (tableData) {
                        $scope.editingData[tableData.id] = false;
                    };
                    $scope.updateItem = function (item) {
                        ArticlesMetaSetupFactory.init($scope.config['factorymodel']).update(item, function (result) {
                            $scope.editingData[item.id] = false;
                            $khMessage.show('Updated successfully');
                        });
                    };
                });

            }
        };
    }]);