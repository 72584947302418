(function (angular) {
    "use strict";
    angular.module('ApiUtils.crop.potato.yieldDig')
        .factory('YieldDigFormConstants', yieldDigFormConstantsFactory);

    function yieldDigFormConstantsFactory($resource) {
        return $resource('api/v1.0/potato/yield-dig/form-constants/', {slug: '@slug'}, {
            query: {
                isArray: false
            }
        });
    }

})(angular);

