angular.module('articles_setup_module').constant('SETUPCONSTANTS', [
    {
        factorymodel: 'categories',
        dialogtitle: 'Topic'
    },
    {
        factorymodel: 'tags',
        dialogtitle: 'Tags'
    },
    {
        factorymodel: 'article-type',
        dialogtitle: 'Format'
    },
    {
        factorymodel: 'content-type',
        dialogtitle: 'Technical Categories'
    },
    {
        factorymodel: 'commodities',
        dialogtitle: 'Crop groups'
    }
]);