angular.module('ArticlesApp').config(['$routeProvider', '$resourceProvider', '$translateProvider', 'formlyConfigProvider',
    function ($routeProvider, $resourceProvider, $translateProvider, formlyConfigProvider) {
        $translateProvider.translations('en', {
            article: 'Article',
            drafts: 'Drafts',
            all: 'All',
            more_news: 'More news',
            more_events: 'More events',
            applied_filters: 'Applied filters',
            more_filters: 'More filters',
            region: 'Region',
            months: 'Months',
            year: 'Year',
            type: 'Type',
            tags: 'Tags',
            crops: 'Crops',
            suggested_links: 'Tools and Links',
            article_type: 'Article type',
            subscription_package: 'Subscription package',
            categories: 'Categories',
            technical_content: 'Technical content',
            featured: 'Featured',
            filter_by_keywords: 'Filter by keywords',
            filter_by_article_type: 'Filter by article type',
            filter_by_technical_categories: 'Filter by technical categories',
            technical_categories: 'Technical categories',
            clear_filters: 'Clear'
        });
        $resourceProvider.defaults.stripTrailingSlashes = false;
        $routeProvider.when('/', {
            name: 'All Articles',
            controller: 'ListOfAllArticlesController',
            templateUrl: '/static/assets/khapps/articles/articles/partials/ListOfAllArticles.html'
        }).when('/article/:slug', {
            name: 'Article',
            controller: 'ArticleController',
            controllerAs: 'vm',
            templateUrl: '/static/assets/khapps/articles/articles/partials/Article.html'
        }).when('/drafts', {
            controller: 'ListOfArticlesInDraft',
            templateUrl: '/static/assets/khapps/articles/articles/partials/ListOfAllDraftArticles.html'
        }).when('/view/:slug', {
            controller: 'ViewArticle',
            templateUrl: '/static/assets/khapps/articles/articles_add_edit_module/partials/ArticleAddEditForm.html'
        }).when('/setup', {
            controller: 'ArticlesSetupController',
            templateUrl: '/static/assets/khapps/articles/setup_module/partials/ArticlesSetup.html'
        }).when('/archive/', {
            controller: 'ArchivedArticlesController',
            templateUrl: '/static/assets/khapps/articles/articles/partials/ListOfAllArticles.html'
        }).when('/support-videos/', {
            controller: 'SupportVideosController',
            templateUrl: '/static/assets/khapps/articles/articles/partials/ListOfAllArticles.html'
        }).otherwise({
            redirectTo: '/'
        });
    }
]);