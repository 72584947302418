(function (angular) {
    "use strict";
    angular.module('ApiUtils.utilities')
        .factory('FilterParameters', FilterParametersFactory);

    FilterParametersFactory.$inject = [];

    function FilterParametersFactory() {

        return {getFilterParameters: getFilterParameters};

        function getFilterParameters(rootScope, listOfParams) {
            var queryParams = {};
            if (listOfParams.includes("team")) {extractTeam(rootScope, queryParams)}
            if (listOfParams.includes("growing_season")) {extractSeason(rootScope, queryParams)}
            if (listOfParams.includes("farm")) {extractFarm(rootScope, queryParams)}
            if (listOfParams.includes("field")) {extractField(rootScope, queryParams)}
            return queryParams;
        }

        function extractTeam(rootScope, queryParams) {
            if (rootScope.hasOwnProperty("selected_team") && rootScope.selected_team !== "All Teams") {
                queryParams["team"] = rootScope.selected_team
            }
        }

        function extractSeason(rootScope, queryParams) {
            if (rootScope.hasOwnProperty("selected_growing_season") && rootScope.selected_growing_season.value !== "All Seasons") {
                queryParams["growing_season"] = rootScope.selected_growing_season.value
            }
        }

        function extractFarm(rootScope, queryParams) {
            if (rootScope.hasOwnProperty("selected_farm") && rootScope.selected_farm.value !== "All Farms") {
                queryParams["farm"] = rootScope.selected_farm.value
            }
        }

        function extractField(rootScope, queryParams) {
            if (rootScope.hasOwnProperty("selected_field") && (rootScope.selected_field.value !== "All Fields" || rootScope.selected_field.value !== null)) {
                queryParams["field"] = rootScope.selected_field.value
            }
        }

    }

})(angular);
