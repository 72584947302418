angular.module('ApiUtils.ksat').factory('SatellitePublishedDatasetResource', function ($resource) {
    return $resource('/api/satellite/publish/', {}, {
        get: {
            params: {publish_slug: '@publish_slug'},
            url: '/api/satellite/publish/:publish_slug',
            method: 'GET'
        },
        query: {
            params: {team: '@team', field_slug: '@field_slug', growing_season: '@growing_season'},
            isArray: false
        },
        delete: {
            params: {publish_slug: '@publish_slug', delete_reason_slug: '@delete_reason_slug'},
            url: '/api/satellite/publish/:publish_slug',
            method: 'DELETE'
        }
    })
});
