(function (angular) {
    'use strict';
    angular.module('ArticlesApp')
        .controller('ArticleController', function ArticleController($scope, $rootScope, $routeParams, ArticlesFactory,
                                                                    $sce, CheckTrialFactory, $mdDialog, ArticleUtilsService) {
            // var vm = this;

            $rootScope.page_id = 'articles';
            $rootScope.currentNavItem = 'single_article';
            $rootScope.sub_app_page_id = '';
            $rootScope.leftsidenavIsOpen = false;
            var RAPIDREPORT = 'Rapidtrialreport';
            var MEMBERREPORT = 'Memberreport';
            var count_setinterval = 0;
            CheckTrialFactory.get(function (result) {
                $scope.subscription_status = result;
            });

            $scope.article_slug = $routeParams.slug;

            // ArticlesFactory.get({slug: $scope.article_slug}, function (result) {
            //     $scope.article = result;
            //     // getArticlesForFilters({category: result.category[0]});
            //     $scope.article.content = $sce.trustAsHtml(result.content);
            // });

            $scope.show_secondary_nav_filters = false;
            ArticlesFactory.get({slug: $scope.article_slug}, function (result) {
                $scope.article = result.data;
                getArticlesForFilters({category: $scope.article.category[0]});
                $scope.article.content = $sce.trustAsHtml($scope.article.content);
                ArticleUtilsService.ArticleHelpers().disable_editable_divs();

                if ($scope.article.article_type_fk === RAPIDREPORT) {
                    $scope.set_interval = setInterval(function () {
                        ArticleUtilsService.ArticleHelpers().disable_editable_divs();
                        count_setinterval++;
                        check_count_of_setinterval();
                    }, 5000);
                }
            });
            $scope.displayOnlyPDF = function (file) {
                if (file) {
                    return file.substr(file.lastIndexOf('.') + 1);
                }
            };

            $scope.editArticle = function () {
                if ($scope.article.article_type_fk_readable == MEMBERREPORT || $scope.article.article_type_fk_readable == RAPIDREPORT) {
                    window.open('/dashboard/trial/#/report/' + $scope.article.slug, '_blank');
                }
                else {
                    $mdDialog.show({
                        controller: 'ArticleAddEditController',
                        templateUrl: '/static/assets/khapps/articles/articles_add_edit_module/partials/ArticleAddEditForm.html',
                        parent: angular.element(document.body),
                        locals: {
                            article_slug: $scope.article_slug
                        },
                        clickOutsideToClose: false
                    });
                }
            };
            $scope.trustContentAsHtml = function (content) {
                return $sce.trustAsHtml(content);
            };
            // check for permissions
            $scope.checkIfAuthor = function (data) {
                return ArticleUtilsService.ArticleHelpers().checkEditPermission();
            };

            function equalise() {
                var maxHeight = 0;
                $('div.related-article-card').each(function () {
                    if ($(this).height() > maxHeight) {
                        maxHeight = $(this).height();
                    }
                });
                $('div.related-article-card-inner').height(maxHeight);
            }

            // Edit article helper
            $scope.EditArticleHelper = function (slug) {
                return ArticleUtilsService.ArticleHelpers().editArticle($location, slug);
            };

            $scope.trustUrl = function (file) {
                return $sce.trustAsResourceUrl('https://docs.google.com/a/kisanhub/viewer?url=' + file + '&embedded=true');
            };

            $scope.trustDocUrl = function (file) {
                return $sce.trustAsResourceUrl('https://view.officeapps.live.com/op/embed.aspx?src=' + file);
            };

            function getArticlesForFilters(filtersDictionary) {
                ArticlesFactory.query({filters: filtersDictionary}, function (result) {
                    $scope.related_articles = result.data;

                    if (result.data.length > 0) {
                        setTimeout(equalise, 4000);
                        if (!ie_11) {
                            ArticleUtilsService.addFlex('md-card');
                        }
                    }
                });
            }

            $scope.get_sharable_link = function () {
                $scope.gen_link = $location.host() + '/dashboard/articles/#/article/' + $scope.article_slug;
                window.open('mailto:?subject=' + encodeURI('Checkout this article') + '&body=' + $scope.gen_link, '_blank');
            };

            $scope.get_article_edit_link = function () {
                if ($scope.article.article_type_fk_readable == MEMBERREPORT || $scope.article.article_type_fk_readable == RAPIDREPORT) {
                    window.open('/dashboard/trial/#/report/' + $scope.article.slug, '_blank');
                } else {
                    $location.path('/edit/' + $scope.article.slug);
                }
            };

            $scope.get_client_icon = function () {
                if (tenant_id == 'niab') {
                    return '/static/assets/khapps2/base/khutils/images/articles/niabtag.png';
                } else {
                    return 'favicon.ico';
                }
            };

            function check_count_of_setinterval() {
                if (count_setinterval > 2) {
                    clearInterval($scope.set_interval);
                }
            }

            $scope.checkForExportAsPdf = function (article) {
                return (article.article_type_fk_readable == MEMBERREPORT || article.article_type_fk_readable == RAPIDREPORT);
            };

            $scope.cancel = function () {
                $mdDialog.hide();
            };
            $scope.showRelatedArticle = function (article_slug) {
                $mdDialog.hide(article_slug);
            };

        });


})(angular);