(function (angular) {
    'use strict';
    angular.module('ApiUtils.khutils.ui')
        .factory('KisanHubIconsResource', KisanHubIconsResource);

    function KisanHubIconsResource() {
        return {
            khIcons: {
                account_remove: "account-remove",
                account_check: "account-check",
                action: "dots-horizontal",
                alert: "alert-circle",
                assign: "account-switch",
                copy: "content-copy",
                close: "close",
                check_status: "checkbox-marked-circle",
                chart_view: "chart-areaspline",
                dialog_close: "keyboard-backspace",
                done: "check",
                export_to_file: "file-export",
                completed_activity: "calendar-check",
                cross_status: "close-circle",
                detail_view: "chart-pie",
                duplicate: "content-duplicate",
                download: "download",
                facebook: "facebook",
                full_extent: "earth",
                hide: "eye-off",
                info: "information-outline",
                linkedin: "linkedin",
                map: "google-maps",
                paste: "content-paste",
                pdf: "file-pdf",
                file_find: "file-find",
                planned_activity: "calendar-blank",
                notification: "bell",
                public: "lock-open",
                private: "lock",
                question_mark: "help-circle",
                reset: "refresh",
                save: "content-save",
                show_lists: "format-list-bulleted",
                settings: "settings",
                share: "share-variant",
                table: "table",
                tag: "tag-heart",
                twitter: "twitter",
                team: "account-multiple",
                upload: "upload",
                user: "account",
                user_location: "crosshairs-gps",
                user_search: "account-search",
                view: "eye",
                newtab: "open-in-new",
                satellite_0: "satellite",
                satellite_1: "satellite-variant",
                calculator: "calculator",
                deselect_all: "select-off",
                printer: "printer",
                crop_book: "book-open-page-variant",
                trial_book: "book-open-page-variant",
                store_book: "book-open-page-variant",
                lab_book: "book-open-page-variant",
                soil_sample: "flask",
                arrow_right: "arrow-right",
                arrow_left: "arrow-left",
                close_circle: "close-circle",
                truck: "truck",
                account_circle: "account-circle",
                barley: "barley",
                weight: "weight",
                alert_outline: "alert-outline",
                contact_mail: 'contact-mail',
                account_supervisor: "account-supervisor"
            }
        };
    }

})(angular);