angular.module('articles_factories').factory('ProfileFactory', ['$resource', function ($resource) {
    return $resource('/api/v1.0/profiles/personalprofile/:user_slug/', {user_slug: '@user_slug'}, {
        update: {
            method: 'PUT',
            transformRequest: function (data) {
                if (data === undefined)
                    return data;
                var fd = new FormData();
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        fd.append(key, data[key]);
                    }
                }
                return fd;
            },
            headers: {'Content-Type': undefined}
        }
    });
}]);