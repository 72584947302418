(function (angular) {
    "use strict";
    angular.module('ApiUtils.crop.potato.yieldDig')
        .factory('yieldDigGradingTemplate', yieldDigGradingTemplateResource);

    yieldDigGradingTemplateResource.$inject = ['$resource'];

    function yieldDigGradingTemplateResource ($resource) {
        var url = "/api/v1.0/potato/yield-dig/grading-templates/:templateSlug";
        return $resource(
            url,

            {
                templateSlug: '@templateSlug'
            },

            {
            query: {
                method: 'GET',
                isArray: true
            },
            get: {
                method: 'GET'
            },
            save: {
                method: 'POST'
            },
            update: {
                method: 'PUT'
            },
            delete: {
                method: 'DELETE'
            }
        });
    }

})(angular);