(function (angular) {
    "use strict";
    angular.module('ApiUtils.utilities')
        .factory('GenericResource', GenericResourceFactory);

    GenericResourceFactory.$inject = [];

    function GenericResourceFactory() {

        function GenericResource () {
            this.resourceState = {
                listParams: null,
                listData: null,
                detailParams: null,
                detailData: null,
                detailIdentifier: null
            };
        }

        // List
        GenericResource.prototype.retrieveList = function (resource, submittedParams, filterFields, successCallback, errorCallback, context) {
            var queryParams = this.buildListParams(submittedParams, filterFields);
            if (this.checkListParams(queryParams, this.resourceState.listParams)) {
                successCallback(this.resourceState.listData, context);
            } else {
                this.queryList(resource, queryParams, successCallback, errorCallback, context)
            }
        };

        GenericResource.prototype.resetList = function () {
            this.clearListParams();
            this.clearListData();
        };

        GenericResource.prototype.hardRefreshList = function(resource, submittedParams, filterFields, successCallback, errorCallback, context) {
            var queryParams = this.buildListParams(submittedParams, filterFields);
            this.queryList(resource, queryParams, successCallback, errorCallback, context)
        };

        // Detail
        GenericResource.prototype.retrieveDetail = function(resource, identifier, submittedParams, filterFields, successCallback, errorCallback, context) {
            var queryParams = this.buildDetailParams(submittedParams, filterFields);
            if (this.checkIdentifier(identifier, this.resourceState.detailIdentifier) && this.checkDetailParams(queryParams, this.resourceState.detailParams)) {
                successCallback(this.resourceState.detailData, context);
            } else {
                this.getDetail(resource, identifier, queryParams, successCallback, errorCallback, context)
            }
        };

        GenericResource.prototype.resetDetail = function () {
            this.clearDetailParams();
            this.clearDetailData();
            this.clearDetailIdentifier();
        };

        GenericResource.prototype.hardRefreshDetail = function (resource, identifier, submittedParams, filterFields, successCallback, errorCallback, context) {
            var queryParams = this.buildDetailParams(submittedParams, filterFields);
            this.getDetail(resource, identifier, queryParams, successCallback, errorCallback, context)
        };

        // Create
        GenericResource.prototype.create = function(resource, submissionData, successCallback, errorCallback, context) {
            var self = this;
            resource.save(
                submissionData,
                function (successResponse) {
                    successCallback(successResponse, context);
                    self.resetList()
                },
                function (errorResponse) {
                    errorCallback(errorResponse, context)
                }
            )
        };

        // Update
        GenericResource.prototype.update = function (resource, identifier, submissionData, successCallback, errorCallback, context) {
            var self = this;
            resource.update(
                identifier,
                submissionData,
                function (successResponse) {
                    successCallback(successResponse, context);
                    self.resetList();
                    if (self.checkIdentifier(identifier, self.resourceState.detailIdentifier)) {self.resetDetail()}
                },
                function (errorResponse) {
                    errorCallback(errorResponse, context)
                }
            )
        };

        // Destroy
        GenericResource.prototype.destroy = function (resource, identifier, successCallback, errorCallback, context) {
            var self = this;
            resource.delete(
                identifier,
                function (successResponse) {
                    successCallback(successResponse, context);
                    self.resetList();
                    if (self.checkIdentifier(identifier, self.resourceState.detailIdentifier)) {self.resetDetail()}
                },
                function (errorResponse) {
                    errorCallback(errorResponse, context)
                }
            )
        };

        // Delete
        GenericResource.prototype.delete = function (resource, identifier, successCallback, errorCallback, context) {
            var self = this;
            resource.delete(
                identifier,
                function (successResponse) {
                    successCallback(successResponse, context);
                    self.resetList();
                    if (self.checkIdentifier(identifier, self.resourceState.detailIdentifier)) {self.resetDetail()}
                },
                function (errorResponse) {
                    errorCallback(errorResponse, context)
                }
            )
        };

        GenericResource.prototype.wipeCache = function () {
            this.resetDetail();
            this.resetList();
        };

        // Underlying logic
        GenericResource.prototype.queryList = function(resource, queryParams, successCallback, errorCallback, context) {
            var self = this;
            resource.query(
                queryParams,
                function (successResponse) {
                    self.resourceState.listParams = queryParams;
                    self.resourceState.listData = successResponse;
                    successCallback(successResponse, context)
                },
                function (errorResponse) {
                    self.resetList();
                    errorCallback(errorResponse, context)
                }
            )
        };

        GenericResource.prototype.getDetail = function (resource, identifier, queryParams, successCallback, errorCallback, context) {
            var self = this;
            resource.get(
                identifier,
                queryParams,
                function (successResponse) {
                    self.resourceState.detailIdentifier = identifier;
                    self.resourceState.detailParams = queryParams;
                    self.resourceState.detailData = successResponse;
                    successCallback(successResponse, context)
                },
                function (errorResponse) {
                    self.resetDetail();
                    errorCallback(errorResponse, context)
                }
            )
        };
        // TODO: switch from array to obj
        GenericResource.prototype.buildListParams = function(submittedParams, filterFields) {
            return this.extractParameters(submittedParams, filterFields);
        };

        GenericResource.prototype.checkListParams = function(queryParams, storedParams) {
            return this.matchParamObjects(queryParams, storedParams)
        };

        GenericResource.prototype.clearListParams = function () {
            this.resourceState.listParams = null;
        };

        GenericResource.prototype.clearListData = function () {
            this.resourceState.listData = null;
        };
        // TODO: switch from array to obj
        GenericResource.prototype.buildDetailParams = function (submittedParams, filterFields) {
            return this.extractParameters(submittedParams, filterFields);
        };

        GenericResource.prototype.checkDetailParams = function (queryParams, detailParams) {
            return this.matchParamObjects(queryParams, detailParams)
        };

        GenericResource.prototype.checkIdentifier = function (newIdentifier, storedIdentifier) {
            return this.matchParamObjects(newIdentifier, storedIdentifier)
        };

        GenericResource.prototype.clearDetailParams = function () {
            this.resourceState.detailParams = null;
        };

        GenericResource.prototype.clearDetailData = function () {
            this.resourceState.detailData = null;
        };

        GenericResource.prototype.clearDetailIdentifier = function () {
            this.resourceState.detailIdentifier = null;
        };

        // Utility functions
        GenericResource.prototype.matchParamObjects = function (newParams, storedParams) {
            return (storedParams && _.isEqual(newParams, storedParams))
        };

        GenericResource.prototype.extractParameters = function (inputObject, filterMap) {
            var listOfKeys = Object.keys(filterMap);
            var filteredObj = _.pick(inputObject, listOfKeys);
            var mappedObj = {};
            angular.forEach(listOfKeys, function (key, index, obj) {
                if (filteredObj.hasOwnProperty(key)) {
                    mappedObj[filterMap[key]] = filteredObj[key];
                }
            });
            return mappedObj;
        };

        return GenericResource;

    }

})(angular);
