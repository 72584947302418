(function (angular) {
    "use strict";
    angular.module('ApiUtils.crop.potato.admin')
        .factory('PotatoAdminYieldForecastSummary', PotatoAdminYieldForecastSummaryFactory);

    function PotatoAdminYieldForecastSummaryFactory($resource) {
        var url = "api/potato/forecast-summary/:teamSlug/admin/";
        // var queryParamDict = {};
        return $resource(url, {teamSlug: '@teamSlug'},
            {
                query: {
                    // params: queryParamDict,
                    url: url
                }
            }
        )
    }
})(angular);

