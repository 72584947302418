(function(angular){
    "use strict";
    angular.module('ApiUtils.khutils.seasons')
        .factory('PlantingSeasons', ["$resource", PlantingSeasonsFactory]);

    function PlantingSeasonsFactory($resource){
        var url = "/api/v1.0/khutils/plantingseasons/";
        return $resource(url, {},
            {
                query: {
                    url: url,
                    isArray: true
                }
            }
        );
    }

})(angular);
