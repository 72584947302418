(function (angular) {
    'use strict';
    angular
        .module('articles_factories')
        .factory('SupportVideosFactory', SupportVideosFactory);
    SupportVideosFactory.$inject = ['$resource'];

    function SupportVideosFactory($resource) {
        return $resource('/api/v2.0/articles/support-videos/:slug/', {slug: '@slug'}, {
            query: {
                isArray: false
            },
            get: {
                method: 'GET'
            },
            delete: {
                url: '/api/v2.0/articles/data/:slug/',
                method: 'DELETE'
            }
        });
    }
}(angular));
