(function (angular) {
    'use strict';
    angular.module('ApiUtils.utilities')
        .factory('TableUtilitiesResource', TableUtilitiesResource);

    TableUtilitiesResource.$inject = [];

    function TableUtilitiesResource() {

        return {tableFilter: getTableFilter};

        function getTableFilter ($filter, key, type, placeholder) {
            var dict = {};
            dict[key] = {
                id: type,
                placeholder: $filter('translate')(placeholder)
            };
            return dict;
        }

    }

})(angular);