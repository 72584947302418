angular.module('articles_setup_module').controller('ArticlesSetupController', ['$scope', '$rootScope',
    function ($scope, $rootScope) {
        $rootScope.page_id = 'articles';
        $rootScope.sub_app_page_id = 'mydocs_setup';
        $rootScope.currentNavItem = 'setup';
        $scope.setupCards = [
            {
                card_title: 'TOOLS AND LINKS',
                kh_card_content_template: '/static/assets/khapps/articles/suggested_links/suggested_links.html',
                card_flex: 100
            },
            {
                card_title: 'Meta',
                kh_card_content_template: '/static/assets/khapps/articles/setup_module/partials/articles_meta_setup_card.html',
                card_flex: 50
            },
            {
                card_title: 'Subscription packages',
                kh_card_content_template: '/static/assets/khapps/articles/setup_module/partials/subscription_package.html',
                card_flex: 50
            }
        ];
    }]);