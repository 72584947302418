angular.module('articles_factories').factory('ArticlesMetaSetupFactory', ['$resource', function ($resource) {
    return {
        init: function (model) {
            var URL = '/api/v2.0/articles/' + model + '/:id/';
            return $resource(URL, {id: '@id'}, {
                save: {
                    method: 'POST'
                },
                update: {
                    method: 'PUT'
                },
                get: {
                    method: 'GET'
                }
            });
        }
    };
}]);