angular.module('articles_setup_module').controller('ArticlesMetaAddSetupController', ['$scope', 'factorymodel', 'dialogtitle', 'ArticlesMetaSetupFactory', '$mdDialog',
    function ($scope, factorymodel, dialogtitle, ArticlesMetaSetupFactory, $mdDialog) {
        $scope.model = {};
        $scope.dialogtitle = dialogtitle;
        $scope.form = [{
            'key': 'title',
            'type': 'input',
            'className': 'flex-100',
            'templateOptions': {
                'label': 'Title',
                focus: true,
                required: true
            }
        }];
        $scope.addNewItem = function () {
            ArticlesMetaSetupFactory.init(factorymodel).save($scope.model, function (res) {
                console.log(res);
                $mdDialog.hide();
            }, function (errr) {
                console.log(errr);
            });
        };
        $scope.cancel = function () {
            $mdDialog.hide();
        };
    }]);