(function(angular){
    'use strict';
    angular.module('ApiUtils.crowdsource')
        .factory('OSRGrowthConstantsFactory', OSRGrowthConstantsFactory);

    function OSRGrowthConstantsFactory($resource){
        return $resource('/api/v1.0/crowdsource/oilseedrapegrowthconstants/', {},
            {
                query: {
                    method: 'GET',
                    isArray: false
                }
            });
    }

})(angular);