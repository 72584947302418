(function (angular) {
    "use strict";

    angular.module('ApiUtils', [
        'ApiUtils.utilities',
        'ApiUtils.khutils',
        'ApiUtils.fms',
        'ApiUtils.crop',
        'ApiUtils.dms',
        'ApiUtils.ksat',
        'ApiUtils.lab'
    ])

})(angular);