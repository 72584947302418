angular.module('ArticlesApp').controller("ListOfArticlesInDraft",
    function ($scope, $rootScope, $location, DraftArticlesFactory, $mdDialog, $sce, ArticleUtilsService) {
        $rootScope.show_filters = false;
        $scope.permission = ArticleUtilsService.ArticleHelpers().checkGlobalEditPermission();
        $rootScope.currentNavItem = "drafts";
        $rootScope.page_id = "articles";
        $rootScope.sub_app_page_id = "mydocs_drafts";
        $scope.show_secondary_nav_filters = true;

        function get_draft_articles() {
            DraftArticlesFactory.query(function (result) {
                $scope.draft_articles = result;
                if (ie_11) {
                    $scope.IS_IE = true;
                }
            });
        }


        if ($scope.permissionToAddEdit) {
            get_draft_articles();
        } else {
            $location.path('/');
        }
        $rootScope.$on('article_saved', function () {
            get_draft_articles();
        });
        var RAPIDREPORT = "Rapidtrialreport";
        var MEMBERREPORT = "Memberreport";

        $scope.get_article_edit_link = function (article) {
            if (article.article_type_fk_readable == MEMBERREPORT || article.article_type_fk_readable == RAPIDREPORT) {
                window.open("/dashboard/trial/#/report/" + article.slug, '_blank');
            } else {
                $location.path("/edit/" + article.slug)
            }
        };
        $scope.editArticle = function (article_slug, article) {
            if (article && (['Memberreport', 'Rapidtrialreport'].indexOf(article.article_type_fk) !== -1)) {
                window.open('/dashboard/trial/#/report/' + article_slug, '_blank');
            } else {
                $mdDialog.show({
                    controller: "ArticleAddEditController",
                    templateUrl: '/static/assets/khapps/articles/articles_add_edit_module/partials/ArticleAddEditForm.html',
                    parent: angular.element(document.body),
                    locals: {
                        article_slug: article_slug
                    },
                    clickOutsideToClose: false
                })
            }

        };
        $scope.showArticle = function (article_slug) {
            $mdDialog.show({
                controller: "SingleArticleController",
                templateUrl: '/static/assets/khapps/articles/articles/partials/SingleArticle.html',
                parent: angular.element(document.body),
                locals: {
                    article_slug: article_slug
                },
                clickOutsideToClose: false
            })
        };
        $scope.checkForClearing = function (search_query) {
            if (search_query.length == 0) {
                delete $scope.search_query;
                delete $scope.filter;
            }
        };
        $scope.clearSearch = function () {
            delete $scope.search_query;
            delete $scope.filter;
        };
        $scope.searchArticles = function (query) {
            $scope.filter = query;
        };
        $scope.trustContentAsHtml = function (content) {
            return $sce.trustAsHtml(content);
        };
    });

