(function(angular){
    "use strict";
    angular.module('ApiUtils.khutils.teams')
        .factory('PrimarySecondaryTeams', ["$resource", PrimarySecondaryTeamsFactory]);

    function PrimarySecondaryTeamsFactory($resource){
        var url = "/api/v1.0/khutils/primary-secondary-teams/";
        return $resource(url, {},
            {
                query: {
                    url: url,
                }
            }
        );
    }

})(angular);