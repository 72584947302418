(function (angular) {
    "use strict";
    angular.module('ApiUtils.khauth.auth')
        .factory('KisanAuthTokenServiceFactory', ["$resource", KisanAuthTokenServiceFactory]);

    function KisanAuthTokenServiceFactory($resource) {
        return {
            init: function (appKhAuthBaseUrl) {
                var baseApiUrl = appKhAuthBaseUrl + '/oauth/token/';
                return $resource(baseApiUrl, {}, {
                    save: {
                        params: {
                            grant_type: '@grant_type',
                            client_id: '@client_id',
                            client_secret: '@client_secret',
                            scope: '@read_write_scope'
                        },
                        method: 'POST'
                    }
                });
            }
        }
    }

})(angular);
