angular.module("articles_adapter_module").factory("ArticlesFactory", ["$resource", function ($resource) {
    return $resource('/api/v2.0/articles/data/:slug/', {slug: '@slug'}, {
        query: {
            isArray: false
        },
        save: {
            method: 'POST'
        },
        update: {
            method: 'PUT'
        },
        get: {
            method: 'GET'
        }
    });
}]);