(function (angular) {
    "use strict";
    angular.module('ApiUtils.crop.potato.dashboard')
        .factory('PotatoFieldPlotPlantingSystem', PotatoFieldPlotPlantingSystemFactory);

    function PotatoFieldPlotPlantingSystemFactory($resource) {
        return $resource('api/v1.0/potato/field-plot-planting-systems/:plotSlug/',
            {plotSlug: '@plotSlug'},
            {
                query: {
                    method: 'GET',
                    isArray: true
                },
                save: {
                    method: 'POST'
                },
                update: {
                    method: 'PUT'
                }
            }
        );
    }

})(angular);