(function (angular) {
    'use strict';
    angular.module('articles_factories')
        .factory('ArticleConstantsResource', ArticleConstantsResource);

    ArticleConstantsResource.$inject = ['$resource'];

    function ArticleConstantsResource($resource) {
        var baseApiUrl = '/api/v2.0/articles/articleconstants/';
        return $resource(baseApiUrl, {}, {
            query: {
                isArray: false
            }
        });
    }

})(angular);