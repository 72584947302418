(function(angular){
    "use strict";
    angular.module('ApiUtils.crop.potato.admin')
        .factory('PotatoYieldDigResultsAdmin', PotatoYieldDigsAdminFactory);

    PotatoYieldDigsAdminFactory.$inject = ['$resource'];

    function PotatoYieldDigsAdminFactory($resource){
        var url = "api/v1.0/potato/yield-dig/results/:teamSlug/admin/";
        return $resource(url, {teamSlug: '@teamSlug'}, {
            get: {
                isArray: true
            }
        });
    }

})(angular);
