(function(angular){
    "use strict";
    angular.module('ApiUtils.crop.potato.dashboard')
        .factory('PotatoPlantingSystems', PotatoPlantingSystemsFactory);

    PotatoPlantingSystemsFactory.$inject = ['$resource'];

    function PotatoPlantingSystemsFactory($resource){
        return $resource('api/v1.0/potato/planting-systems/', {}, {
            query: {
                isArray: true
            }
        });
    }

})(angular);
