(function (angular) {
    "use strict";
    angular.module('ApiUtils.crop.potato.dashboard')
        .factory('PotatoOperations', PotatoOperationsFactory);

    function PotatoOperationsFactory($resource) {
        return $resource('/api/v1.0/operations/data/:operation_slug/', {
            operation_slug: '@operation_slug'
        }, {
            query: {
                method: "GET"
            },
            save: {
                method: 'POST'
            },
            update: {
                method: 'PUT'
            },
            switchStatus: {
                method: 'PATCH',
                params: {method: "status"}
            }
        });
    }

})(angular);