angular.module("articles_adapter_module").controller("ArticleAdapterDisplayController",
    ["$scope", "article_data", "$sce",
        function ($scope, article_data, $sce) {
            $scope.article = article_data;
            $scope.displayOnlyPDF = function (file) {
                if (file) {
                    return file.substr(file.lastIndexOf('.') + 1);
                }
            };

	        $scope.trustUrl = function (file) {
		        return $sce.trustAsResourceUrl('https://docs.google.com/a/kisanhub/viewer?url=' + file + '&embedded=true');
	        };

	        $scope.trustDocUrl = function (file) {
                return $sce.trustAsResourceUrl('https://view.officeapps.live.com/op/embed.aspx?src=' + file);
            };
        }
    ]);
