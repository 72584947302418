(function (angular) {
    "use strict";
    angular.module('ApiUtils.fms.fieldplotcrop')
        .factory('FieldPlotCropFactory', ["$resource", FieldPlotCropFactory]);

    function FieldPlotCropFactory($resource) {
        var url = "/api/v1.0/fms/legacy-mongo/fieldplotcrop/";
        var queryParamDict = {
            field_plot_crop_slug: '@field_plot_crop_slug',
            farm: '@farm',
            field: '@field',
            growing_season: '@growing_season',
            data_format: '@data_format'
        };

        return $resource(url, {}, {
            save: {
                method: 'POST'
            },
            get: {
                params: queryParamDict,
                url: url + ':field_plot_crop_slug',
                method: 'GET'
            },
            query: {
                params: queryParamDict,
                isArray: true
            }
        });
    }

})(angular);
