(function(angular){
    "use strict";
    angular.module('ApiUtils.crop.potato.admin')
        .factory('PotatoAdminSummary', PotatoAdminSummaryFactory);

    function PotatoAdminSummaryFactory($resource){
        return $resource('api/v1.0/potato/team-service-subscriptions/admin/', {}, {
            query: {
                isArray: true
            }
        });
    }

})(angular);