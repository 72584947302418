(function (angular) {
    "use strict";
    angular.module('ApiUtils.crop.potato.dashboard')
        .factory('PotatoPlotOperations', PotatoPlotOperationsFactory);

    function PotatoPlotOperationsFactory($resource) {
        return $resource('/api/plot/:plot_id/', {plotSlug: '@plotSlug'}, {
            query: {
                method: "GET"
            },
            get: {
                method: "GET"
            },
            save: {
                method: 'POST'
            },
            update: {
                method: 'PUT'
            }
        });
    }

})(angular);
