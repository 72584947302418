/* eslint-disable angular/controller-as,angular/window-service */
angular.module('ArticlesApp').controller('ArchivedArticlesController',
    function ($scope, $routeParams, $location, ArchivedArticlesFactory, RegionsFactory, ArticlesMetaSetupFactory,
              $timeout, $mdDialog, $rootScope, $mdSidenav, $sce, $anchorScroll, $khMessage, ArticleUtilsService) {
        // eslint-disable-next-line no-undef
        $scope.currentPage = 1;
        ArticleUtilsService.ArticlesListingManager($scope, $location, ArchivedArticlesFactory, RegionsFactory, ArticlesMetaSetupFactory,
            $mdDialog, $mdSidenav, $rootScope, $sce, $anchorScroll, $khMessage, true);
        $rootScope.page_id = 'articles';
        $rootScope.sub_app_page_id = 'mydocs_archive';
        // eslint-disable-next-line no-undef
        $scope.permission = ArticleUtilsService.ArticleHelpers().checkGlobalEditPermission();
        var RAPIDREPORT = 'Rapidtrialreport';
        var MEMBERREPORT = 'Memberreport';
        $scope.show_suggested_links = false;
        if ($routeParams.slug) {
            $mdDialog.show({
                controller: 'SingleArticleController',
                templateUrl: '/static/assets/khapps/articles/articles/partials/SingleArticle.html',
                // eslint-disable-next-line angular/document-service
                parent: angular.element(document.body),
                locals: {
                    article_slug: $routeParams.slug
                },
                clickOutsideToClose: false
            }).then(function (error) {
                console.log(error);
            });
        }
        $scope.get_article_edit_link = function (article) {
            if (article.article_type_fk_readable === MEMBERREPORT || article.article_type_fk_readable === RAPIDREPORT) {
                window.open('/dashboard/trial/#/report/' + article.slug, '_blank');
            } else {
                $location.path('/edit/' + article.slug);
            }
        };
    });