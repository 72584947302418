(function(angular){
    "use strict";
    angular.module('ApiUtils.crop.potato.admin')
        .factory('PotatoAdminDashboard', PotatoAdminDashboardFactory);

    function PotatoAdminDashboardFactory($resource){
        var url = "api/potato/dashboard/:teamSlug/admin/";
        return $resource(url, {teamSlug: '@teamSlug'}, {
            get: {
                isArray: true
            }
        });
    }

})(angular);
