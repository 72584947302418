angular.module('ApiUtils.ksat')
        .factory('SatelliteServiceTimeSeriesResource', function($resource) {
    return $resource('/api/satellite/time-series/', {}, {
        get: {
            params: {field_slug: '@field_slug'},
            url: '/api/satellite/time-series/:field_slug/',
            method: 'GET'
        },
    });
});
