(function (angular) {
    "use strict";
    angular.module('ApiUtils.fms.cropProgress')
        .factory('HarvestProgressFactory', ["$resource", HarvestProgressFactory]);

    function HarvestProgressFactory($resource) {
        var url = "/api/v1.0/farm-reports/harvest-progress/";
        var queryParamDict = {
            farm: '@farm',
            numberofseasons: '@numberofseasons'
        };

        return $resource(url, {},
            {
                get: {
                    params: queryParamDict,
                    url: url
                }
            }
        );
    }

})(angular);