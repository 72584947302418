(function(angular){
    "use strict";
    angular.module('ApiUtils.notification.userPreferences')
        .factory('CommunicationLanguageFactory', CommunicationLanguageFactory);

    function CommunicationLanguageFactory($resource){
        return $resource('/api/v2.0/notification/user_notification_preferences/communicationlanguage/', {}, {
            query: {
                method: 'GET',
                isArray: false
            }
        });
    }

})(angular);