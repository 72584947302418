(function (angular) {
    'use strict';
    angular.module('ApiUtils.fms.farmfield')
        .factory('FarmFieldResource', FarmFieldResource);

    FarmFieldResource.$inject = ['$resource'];

    function FarmFieldResource($resource) {
        var url = '/api/v1.0/fms/farmfield/';
        var queryParamDict = {
            field_slug: '@field_slug',
            data_format: '@data_format'
        };

        return $resource(url, {},
            {
                save: {
                    method: 'POST'
                },
                get: {
                    params: queryParamDict,
                    url: '/api/v1.0/fms/farmfield/:field_slug/',
                    method: 'GET'
                },
                update: {
                    params: queryParamDict,
                    url: '/api/v1.0/fms/farmfield/:field_slug/',
                    method: 'PUT'
                },
                delete: {
                    params: queryParamDict,
                    url: '/api/v1.0/fms/farmfield/:field_slug/',
                    method: 'DELETE'
                }
            });
    }

})(angular);
