(function(angular){
    "use strict";
    angular.module('ApiUtils.settings.djangoSite')
        .factory('DjangoSiteFactory', DjangoSiteFactory);

    function DjangoSiteFactory($resource){
        return $resource('/api/v1.0/khcore/sites/', {}, {
            query: {
                method: 'GET',
                isArray: true
            }
        });
    }

})(angular);