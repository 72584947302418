angular.module('ArticlesApp').factory('CheckTrialFactory', ['$resource', function ($resource) {
    return $resource('/api/v1.0/payment/subscribeuser/', {}, {});
}]);
angular.module('ArticlesApp').controller('SingleArticleController',
    function ($sce, $scope, $rootScope, $location, $routeParams, ArticlesFactory, ArticlesReportFactory,
              article_slug, $mdDialog, CheckTrialFactory, clipboard, $khMessage, ProfileFactory, PlatformSettingsFactory, ArticleUtilsService) {
        var RAPIDREPORT = 'Rapidtrialreport';
        var MEMBERREPORT = 'Memberreport';
        var count_setinterval = 0;

        $scope.clipboard_supported = clipboard.supported;
        if (!clipboard.supported) {
        }

        ProfileFactory.get(function (result) {
            $scope.profile = result;
        });

        PlatformSettingsFactory.get({}, function (result) {
            $scope.platform_settings = result;
        });

        CheckTrialFactory.get(function (result) {
            $scope.subscription_status = result;
        });
        $scope.show_secondary_nav_filters = false;
        ArticlesFactory.get({slug: article_slug}, function (result) {
            $scope.article = result.data;
            if ($scope.article.status === 2) {
                getArticlesForFilters({category: $scope.article.category[0]});
            }
            $scope.article.content = $sce.trustAsHtml($scope.article.content);
            ArticleUtilsService.ArticleHelpers().disable_editable_divs();

            if ($scope.article.article_type_fk == RAPIDREPORT) {
                $scope.set_interval = setInterval(function () {
                    ArticleUtilsService.ArticleHelpers().disable_editable_divs();
                    count_setinterval++;
                    check_count_of_setinterval();
                }, 5000);
            }
        });
        $scope.displayOnlyPDF = function (file) {
            if (file) {
                return file.substr(file.lastIndexOf('.') + 1);
            }
        };


        $scope.editArticle = function () {
            if ($scope.article.article_type_fk_readable == MEMBERREPORT || $scope.article.article_type_fk_readable == RAPIDREPORT) {
                window.open('/dashboard/trial/#/report/' + $scope.article.slug, '_blank');
            }
            else {
                $mdDialog.show({
                    controller: 'ArticleAddEditController',
                    templateUrl: '/static/assets/khapps/articles/articles_add_edit_module/partials/ArticleAddEditForm.html',
                    parent: angular.element(document.body),
                    locals: {
                        article_slug: article_slug
                    },
                    clickOutsideToClose: false
                });
            }
        };
        $scope.trustContentAsHtml = function (content) {
            return $sce.trustAsHtml(content);
        };
        // check for permissions
        $scope.checkIfAuthor = function (data) {
            return ArticleUtilsService.ArticleHelpers().checkEditPermission();
        };

        function equalise() {
            var maxHeight = 0;
            $('div.related-article-card').each(function () {
                if ($(this).height() > maxHeight) {
                    maxHeight = $(this).height();
                }
            });
            $('div.related-article-card-inner').height(maxHeight);
        }

        // Edit article helper
        $scope.EditArticleHelper = function (slug) {
            return ArticleUtilsService.ArticleHelpers().editArticle($location, slug);
        };

        $scope.trustUrl = function (file) {
            return $sce.trustAsResourceUrl('https://docs.google.com/a/kisanhub/viewer?url=' + file + '&embedded=true');
        };

        $scope.trustDocUrl = function (file) {
            return $sce.trustAsResourceUrl('https://view.officeapps.live.com/op/embed.aspx?src=' + file);
        };

        function getArticlesForFilters(filtersDictionary) {
            ArticlesFactory.query({filters: filtersDictionary}, function (result) {
                $scope.related_articles = result.data;

                if (result.data.length > 0) {
                    setTimeout(equalise, 4000);
                    if (!ie_11) {
                        ArticleUtilsService.addFlex('md-card');
                    }
                }
            });
        }


        $scope.get_sharable_link = function () {
            var gen_link = 'https://' + $location.host() + '/dashboard/articles/#/article/' + article_slug;
            var mail_body = $scope.profile.full_name +
                ' would like to share the following link with you:%0D%0A' +
                $scope.article.title +
                '%0D%0A' + gen_link +
                '%0D%0A%0D%0AKind regards,%0D%0A' +
                $scope.platform_settings.SITE_TITLE;
            window.open('mailto:?subject=' + encodeURI($scope.article.title) + '&body=' + mail_body, '_blank');
        };

        $scope.copy_to_clipboard = function () {
            $scope.gen_link = 'https://' + $location.host() + '/dashboard/articles/#/article/' + article_slug;
            clipboard.copyText($scope.gen_link);
            $khMessage.show('Copied.', 500);
        };

        $scope.get_article_edit_link = function () {
            if ($scope.article.article_type_fk_readable == MEMBERREPORT || $scope.article.article_type_fk_readable == RAPIDREPORT) {
                window.open('/dashboard/trial/#/report/' + $scope.article.slug, '_blank');
            } else {
                $location.path('/edit/' + $scope.article.slug);
            }
        };

        $scope.get_client_icon = function () {
            if (tenant_id == 'niab') {
                return '/static/assets/khapps2/base/khutils/images/articles/niabtag.png';
            } else {
                return 'favicon.ico';
            }
        };

        function check_count_of_setinterval() {
            if (count_setinterval > 2) {
                clearInterval($scope.set_interval);
            }
        }

        $scope.checkForExportAsPdf = function (article) {
            return (article.article_type_fk_readable == MEMBERREPORT || article.article_type_fk_readable == RAPIDREPORT);
        };

        $scope.cancel = function () {
            $mdDialog.hide();
        };
        $scope.showRelatedArticle = function (article_slug) {
            $mdDialog.hide(article_slug);
        };
    });
angular.module('ArticlesApp').controller('ViewArticle', ['$scope', '$location', '$routeParams', '$mdDialog',
    function ($scope, $location, $routeParams, $mdDialog) {
        if ($routeParams.slug) {
            $mdDialog.show({
                controller: 'SingleArticleController',
                templateUrl: '/static/assets/khapps/articles/articles/partials/SingleArticle.html',
                parent: angular.element(document.body),
                locals: {
                    article_slug: $routeParams.slug
                },
                clickOutsideToClose: false,
                escapeToClose: false
            }).then(function () {
                window.close();
            });
        }
    }]
);
