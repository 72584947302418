(function(angular){
    'use strict';
    angular.module('ApiUtils.crowdsource')
        .factory('OSRGrowthFactory', OSRGrowthFactory);

    function OSRGrowthFactory($resource){
        return $resource('/api/v1.0/crowdsource/oilseedrapegrowth/', {},
            {
                query: {
                    method: 'GET',
                    isArray: true
                },
                save: {
                    method: 'POST'
                }
            });
    }

})(angular);