angular.module('ApiUtils.ksat')
        .factory('SatelliteServiceSubscriptionResource', function($resource) {
    return $resource('/api/satellite/subscribe/', {}, {
        save: {
            method: 'POST'
        },
        get: {
            params: {publish_slug: '@publish_slug'},
            url: '/api/satellite/subscribe/:slug',
            method: 'GET'
        },
        query: {
            params: {team: '@team', field_slug: '@field_slug', service: '@service', growing_season: '@growing_season'},
            isArray: false
        }
    });
});
