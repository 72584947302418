angular.module("articles_adapter_module").controller("ArticleAdapterSetupController", ["$scope", "dialogtitle", "articleadapterdata",
    "$mdDialog", "fileUploads", "ArticleAdapterFactory", "ArticlesFactory", "$khMessage",
    function ($scope, dialogtitle, articleadapterdata, $mdDialog, fileUploads, ArticleAdapterFactory, ArticlesFactory, $khMessage) {
        $scope.model = {};
        $scope.dialogtitle = dialogtitle;

        $scope.fileuploadsconfig = {
            show_files: true,
            app_tag: 'Store',
            max_files: 3,
            db_type: 'mysql',
            display_type: 'detailed',
            allow_duplicates: true
        };

        $scope.form = [{
            "key": "title",
            "type": "input",
            "className": "flex-100",
            "templateOptions": {
                "label": "Title",
                required: true
            }
        },
            {
                "key": "content",
                "type": "textarea",
                "className": "flex-100",
                "templateOptions": {
                    "label": "Message",
                    "rows": 3,
                    required: true
                }
            }
        ];


        if(articleadapterdata.hasOwnProperty('data')){
            $scope.model = articleadapterdata.data;
        }
        if ($scope.model && $scope.model.slug) {
            fileUploads.displayExistingFiles(articleadapterdata.data.files, "mysql");
        }

        $scope.saveArticleAdapter = function (model) {
            $scope.model['files'] = fileUploads.saveFiles();
            if ($scope.model.slug) {
                ArticlesFactory.update($scope.model, function (data) {
                    $khMessage.show("Updated Successfully", 5000);
                    $mdDialog.hide([data.data]);
                });
            }
            else {
                model = jQuery.extend(articleadapterdata, model);
                model['files'] = fileUploads.saveFiles();
                ArticleAdapterFactory.save(model, function (data) {
                    $khMessage.show("Created Successfully", 5000);
                    $mdDialog.hide([data]);
                }, function () {
                    $khMessage.show("Failed", 5000);
                });
            }

        };
        $scope.cancel = function () {
            $mdDialog.cancel()
        };
    }]);