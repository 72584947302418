(function(angular){
    "use strict";
    angular.module('ApiUtils.growers_dashboard')
        .factory('GenericCropDashboard', GenericCropDashboardFactory);

    function GenericCropDashboardFactory($resource){
        return $resource('api/v1.0/crop_dashboard/milestones/', {}, {
            query: {
                isArray: false
            }
        });
    }

})(angular);