/* eslint-disable angular/document-service */
angular.module('ArticlesApp').controller('ArticlesSecondaryNavController',
    function ($scope, $mdDialog, $rootScope, ArticleConstantsFactory, ArticleUtilsService) {
        ArticleConstantsFactory.get(function (result) {
            $scope.constants = result;
        });

        /* eslint-disable no-undef */
        $scope.monthNames = ArticleUtilsService.ArticleHelpers().getMonthNames;
        $scope.tenant_id = tenant_id;
        $scope.permission = ArticleUtilsService.ArticleHelpers().checkGlobalEditPermission();
        /* eslint-enable no-undef */
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////   Filters     ////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
        $scope.addExternalLink = function () {
            $mdDialog.show({
                controller: 'SuggestedLinksAddEditController',
                templateUrl: '/static/assets/khapps/articles/suggested_links/AddSuggestedLinks.html',
                parent: angular.element(document.body),
                clickOutsideToClose: false
            });
        };
        $scope.addSubscriptionPackage = function () {
            $mdDialog.show({
                controller: 'SubscriptionPackageController',
                templateUrl: '/static/assets/khapps/articles/includes/AddSubscriptionPackage.html',
                parent: angular.element(document.body),
                clickOutsideToClose: false
            });
        };
        $scope.addNewArticle = function () {
            $mdDialog.show({
                controller: 'ArticleAddEditController',
                skipHide: true,
                multiple: true,
                templateUrl: '/static/assets/khapps/articles/articles_add_edit_module/partials/ArticleAddEditForm.html',
                parent: angular.element(document.body),
                locals: {
                    article_slug: ''
                },
                clickOutsideToClose: false
            });
        };

        var meta_setup_options = {
            'article-type': {
                factorymodel: 'article-type',
                dialogtitle: 'Format'
            },
            'categories': {
                factorymodel: 'categories',
                dialogtitle: 'Topic'
            },
            'content-type': {
                factorymodel: 'content-type',
                dialogtitle: 'Technical Category'
            },
            'tags': {
                factorymodel: 'tags',
                dialogtitle: 'Tag'
            },
            'commodities': {
                factorymodel: 'commodities',
                dialogtitle: 'Crop group'
            }
        };


        $scope.addType = function (model) {
            $mdDialog.show({
                controller: 'ArticlesMetaAddSetupController',
                template: '<articles-meta-setup></articles-meta-setup>',
                parent: angular.element(document.body),
                locals: {
                    factorymodel: meta_setup_options[model]['factorymodel'],
                    dialogtitle: meta_setup_options[model]['dialogtitle']
                },
                clickOutsideToClose: false
            });
        };


        $scope.addMessage = function () {
            $mdDialog.show({
                controller: 'ContentDeliveryController',
                template: '<content-delivery-directive></content-delivery-directive>',
                parent: angular.element(document.body),
                clickOutsideToClose: false
            });
        };


        $scope.addArticle = function () {
            $mdDialog.show();
        };
        $rootScope.regionFilterBroadcast = function (item) {
            $rootScope.$broadcast('region_filtered', item);
        };
        $rootScope.articleTypeFilterBroadcast = function (item) {
            $rootScope.$broadcast('selected_type', item);
        };
        $rootScope.yearFilterBroadcast = function (item) {
            $rootScope.$broadcast('selected_year', item);
        };
        $rootScope.$on('filters_cleared', function (ev) {
            $scope.selected_year = null;
            $scope.selected_type = null;
            $scope.selected_region = null;
        });
        $rootScope.$on('selected_year_cleared', function (ev) {
            $scope.selected_year = null;
        });
        $rootScope.$on('selected_type_cleared', function (ev) {
            $scope.selected_type = null;
        });
        $rootScope.$on('selected_region_cleared', function (ev) {
            $scope.selected_region = null;
        });
    });