angular.module("articles_adapter_module").factory("ArticleAdapterFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/store/', {}, {
        save: {
            method: 'POST',
            url: '/api/v2.0/articles/articleadapter/'
        },
        query: {
            params: {slug: '@store_slug'},
            url: '/api/v2.0/articles/articleadapter/',
            isArray: true
        }
    });
}]);

