(function (angular) {
    "use strict";
    angular.module('ApiUtils.crop.potato.burndown')
        .factory('PotatoYieldModelOutput', PotatoYieldModelOutputResource);

    PotatoYieldModelOutputResource.$inject = ['$resource'];

    function PotatoYieldModelOutputResource($resource) {
        var url = "api/v1.0/potato/yield-model-output/:model_run_slug/";
        return $resource(
            url,
            {
                model_run_slug: '@model_run_slug'
            },
            {
                query: {
                    method: 'GET',
                    isArray: true
                },
                get: {
                    method: 'GET'
                },
                save: {
                    method: 'POST'
                },
                update: {
                    method: 'PUT'
                },
                delete: {
                    method: 'DELETE'
                }
            }
        );
    }

})(angular);