angular.module('ArticlesSummary').factory('CheckTrialFactory', [
    '$resource',
    function($resource) {
        return $resource('/api/v1.0/payment/subscribeuser/', {}, {});
    }
]);

angular.module('ArticlesSummary').controller('LerapArticleController', [
    '$scope',
    '$mdDialog',
    '$sce',
    'CheckTrialFactory',
    function($scope, $mdDialog, $sce, CheckTrialFactory) {
        CheckTrialFactory.get(function(result) {
            $scope.subscription_status = result;
        });

        $scope.article = {
            created_by: 'webadmin',
            author: ['Andrew Watson, Phil Humphrey'],
            featured_image: [
                {
                    image_name:
                        'pesticide_aquatic_and_arthropod_buffer_zones_explained_november_2016.jpg'
                }
            ],
            files: [
                {
                    file: pdf_filepath,
                    file_name:
                        'Pesticide Aquatic And Arthropod Buffer Zones Explained.pdf'
                }
            ],
            publish_date_readable: 'August 2017',
            region_list: ['National'],
            article_type_fk: 'Article',
            video_content: false,
            article_type_fk_readable: 'Article',
            title: 'Pesticide Aquatic and Arthropod Buffer Zones Explained',
            slug: 'e2ba0605-3bb4-11e7-abe3-784f436386dd',
            region: 'National'
        };
        $scope.cancel = function() {
            $mdDialog.hide();
        };
        $scope.displayOnlyPDF = function(file) {
            if (file) {
                return file.substr(file.lastIndexOf('.') + 1);
            }
        };

        $scope.trustUrl = function(file) {
            return $sce.trustAsResourceUrl(
                'https://docs.google.com/a/kisanhub/viewer?url=' +
                    file +
                    '&embedded=true'
            );
        };

        $scope.trustDocUrl = function(file) {
            return $sce.trustAsResourceUrl(
                'https://view.officeapps.live.com/op/embed.aspx?src=' + file
            );
        };
    }
]);
angular.module('ArticlesSummary').factory('ActivSmartArticlesFactory', [
    '$resource',
    function($resource) {
        return $resource(
            '/api/v2.0/articles/activsmart/:slug/',
            { slug: '@slug' },
            {
                query: {
                    isArray: false
                },
                save: {
                    method: 'POST'
                },
                update: {
                    method: 'PUT'
                },
                get: {
                    method: 'GET'
                }
            }
        );
    }
]);
angular.module('ArticlesSummary').controller('ArticlesSummaryForChemwise', [
    '$scope',
    'ActivSmartArticlesFactory',
    '$mdDialog',
    '$sce',
    'CheckTrialFactory',
    function(
        $scope,
        ActivSmartArticlesFactory,
        $mdDialog,
        $sce,
        CheckTrialFactory
    ) {
        CheckTrialFactory.get(function(result) {
            $scope.subscription_status = result;
        });

        ActivSmartArticlesFactory.query(function(result) {
            $scope.articles = result['results'];
        });
        $scope.showArticle = function(article_slug) {
            callArticleAgain(article_slug);
        };
        $scope.pdf_filepath = $sce.trustAsResourceUrl(
            'https://docs.google.com/gview?url=' +
                pdf_filepath +
                '&embedded=true'
        );

        function callArticleAgain(article_slug) {
            $mdDialog
                .show({
                    controller: 'SingleArticleSummaryController',
                    templateUrl:
                        '/static/assets/khapps/articles/articles/partials/SingleArticle.html',
                    parent: angular.element(document.body),
                    locals: {
                        article_slug: article_slug
                    },
                    clickOutsideToClose: false
                })
                .then(function(some) {
                    if (some) {
                        callArticleAgain(some);
                    }
                });
        }

        $scope.showLerapArticle = function() {
            $mdDialog
                .show({
                    templateUrl:
                        '/static/assets/khapps/chemwise/partials/PdfView.html',
                    controller: 'LerapArticleController',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false
                })
                .then(function(some) {
                    if (some) {
                        callArticleAgain(some);
                    }
                });
        };

        $scope.trustContentAsHtml = function(content) {
            return $sce.trustAsHtml(content);
        };

        $scope.trustContentAsHtml = function(content) {
            return $sce.trustAsHtml(content);
        };
    }
]);
