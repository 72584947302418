/* eslint-disable angular/controller-as,angular/window-service,angular/timeout-service */
angular.module("articles_add_edit_module").controller('ArticleAddEditController',
    function ($scope, $rootScope, ArticlesFactory, ArticlesMetaSetupFactory, TeamUsersFactory, TeamsListFactory, SubscriptionPackageFactory,
              RegionsFactory, article_slug, fileUploads, $khMessage, $mdDialog, CachingUtilsService) {
        $scope.meta = {
            /* eslint-disable no-undef */
            teamusers: CachingUtilsService.addFactoryWithoutCache(TeamUsersFactory),
            teams_list: CachingUtilsService.addFactoryWithoutCache(TeamsListFactory),
            team_regions: CachingUtilsService.addFactoryWithoutCache(RegionsFactory),
            subscription_packages: CachingUtilsService.addFactoryWithoutCache(SubscriptionPackageFactory),
            categories: CachingUtilsService.loadGenericFactory(ArticlesMetaSetupFactory, 'categories', 'article_categories'),
            commodities: CachingUtilsService.loadGenericFactory(ArticlesMetaSetupFactory, 'commodities', 'article_commodities'),
            tags: CachingUtilsService.loadGenericFactory(ArticlesMetaSetupFactory, 'tags', 'article_tags'),
            content_type: CachingUtilsService.loadGenericFactory(ArticlesMetaSetupFactory, 'content-type', 'article_content_type'),
            article_type: CachingUtilsService.loadGenericFactory(ArticlesMetaSetupFactory, 'article-type', 'article_article_type')
            /* eslint-enable no-undef */
        };

        function ArticleAddEditForm() {
            return [
                {
                    className: 'flex-100',
                    type: 'input',
                    key: 'title',
                    templateOptions: {
                        type: 'text',
                        required: true,
                        label: 'Title'
                    }
                },


                {
                    className: 'flex-33',
                    type: 'searchable_select',
                    key: 'subscription_package',
                    templateOptions: {
                        required: true,
                        label: 'Subscription Packages',
                        multiple: true,
                        labelProp: 'name',
                        valueProp: 'id',
                        options: $scope.meta.subscription_packages
                    }
                },
                {
                    className: 'flex-33',
                    type: 'searchable_select',
                    key: 'category',
                    templateOptions: {
                        required: true,
                        label: 'Topics',
                        multiple: true,
                        labelProp: 'title',
                        valueProp: 'id',
                        options: $scope.meta.categories
                    }
                },
                {
                    className: 'flex-33',
                    type: 'searchable_select',
                    key: 'region_list',
                    templateOptions: {
                        required: true,
                        label: 'Groups',
                        multiple: true,
                        labelProp: 'name',
                        valueProp: 'id',
                        options: $scope.meta.team_regions
                    }
                },

                {
                    className: 'flex-100',
                    type: 'ckeditor',
                    key: 'content',
                    templateOptions: {
                        label: 'Content'
                    }
                },

                {
                    className: 'flex-33',
                    type: 'textarea',
                    key: 'description',
                    templateOptions: {
                        label: 'Description'
                    }
                },
                {
                    className: 'flex-33',
                    type: 'searchable_select',
                    key: 'tag_list',
                    templateOptions: {
                        label: 'Tags',
                        multiple: true,
                        labelProp: 'title',
                        valueProp: 'id',
                        options: $scope.meta.tags
                    }
                },
                {
                    className: 'flex-33',
                    type: 'searchable_select',
                    key: 'article_type_fk',
                    templateOptions: {
                        required: true,
                        label: 'Formats',
                        labelProp: 'title',
                        valueProp: 'id',
                        options: $scope.meta.article_type
                    }
                },

                {
                    className: 'flex-25',
                    type: 'searchable_select',
                    key: 'status',
                    templateOptions: {
                        label: 'Status',
                        labelProp: 'name',
                        valueProp: 'value',
                        options: [{
                            name: 'Draft',
                            value: 1
                        }, {
                            name: 'Published',
                            value: 2
                        }]
                    }
                },
                {
                    className: 'flex-25',
                    type: 'searchable_select',
                    key: 'content_type_list',
                    templateOptions: {
                        label: 'Technical categories',
                        multiple: true,
                        labelProp: 'title',
                        valueProp: 'id',
                        options: $scope.meta.content_type
                    }
                },

                {
                    className: 'flex-25',
                    type: 'searchable_select',
                    key: 'commodities_list',
                    templateOptions: {
                        label: 'Crop groups',
                        multiple: true,
                        labelProp: 'title',
                        valueProp: 'id',
                        options: $scope.meta.commodities
                    }
                },
                {
                    className: 'flex-25',
                    type: 'searchable_select',
                    key: 'author',
                    templateOptions: {
                        label: 'Authors',
                        labelProp: 'name',
                        multiple: true,
                        valueProp: 'value',
                        options: $scope.meta.teamusers
                    }
                },
                {
                    className: 'flex-33',
                    type: 'datepicker',
                    key: 'publish_date',
                    templateOptions: {
                        required: true,
                        placeholder: 'dd/mm/yyyy',
                        format: 'DD-MM-YYYY',
                        label: 'Publish date',
                        maxDate: moment().toDate()
                    }
                },
                {
                    className: 'flex-33',
                    type: 'datepicker',
                    key: 'featured_until',
                    templateOptions: {
                        placeholder: 'dd/mm/yyyy',
                        format: 'DD-MM-YYYY',
                        label: 'Set featured until',
                        minDate: $scope.article.publish_date
                    }
                },
                {
                    className: 'flex-33',
                    type: 'datepicker',
                    key: 'expiry_date',
                    templateOptions: {
                        placeholder: 'dd/mm/yyyy',
                        format: 'DD-MM-YYYY',
                        label: 'Expiry date',
                        minDate: $scope.article.publish_date || moment().toDate()
                    }
                }
            ];
        }

        $scope.article_slug = article_slug;
        $scope.imageuploadsconfig = {
            show_images: true,
            app_tag: 'Articles',
            max_files: 1,
            db_type: 'mysql',
            display_type: 'detailed',
            allow_duplicates: true
        };
        $scope.fileuploadsconfig = {
            show_files: true,
            app_tag: 'Articles',
            max_files: 3,
            db_type: 'mysql',
            display_type: 'detailed',
            allow_duplicates: true
        };
        if (article_slug) {
            ArticlesFactory.get({slug: article_slug, type: 'edit'}, function (response) {
                $scope.article = response.data;
                var result = response.data;
                // eslint-disable-next-line no-undef
                if (_.find($scope.meta.article_type, function (type) {
                        if (type.title === 'Rapidtrialreport' || type.title === 'Memberreport') {
                            return type.id === $scope.article.article_type_fk;
                        } else {
                            return false;
                        }
                    })) {
                    window.location.href = '/dashboard/trial/#/report/' + result.slug;
                }
                result.publish_date = new Date(result.publish_date);
                if (result.expiry_date) {
                    result.expiry_date = new Date(result.expiry_date);
                }
                if (result.featured_until) {
                    result.featured_until = new Date(result.featured_until);
                }
                fileUploads.displayExistingImages(result.featured_image, $scope.imageuploadsconfig['db_type']);
                fileUploads.displayExistingFiles(result.files, $scope.fileuploadsconfig['db_type']);
                // eslint-disable-next-line no-undef
                $scope.articles_add_edit_form = ArticleAddEditForm($scope);
            });
        } else {
            $scope.article = {};
            $scope.article.status = 1;
            $scope.article.publish_date = new Date();
            // eslint-disable-next-line no-undef
            $scope.article.author = [logged_in_user[1]];
            // eslint-disable-next-line no-undef
            $scope.articles_add_edit_form = ArticleAddEditForm($scope);
            setTimeout(function () {
                // eslint-disable-next-line no-undef
                $scope.article.region_list = _.find($scope.meta.team_regions, function (type) {
                    if (type.name === 'National') {
                        return type.id;
                    }
                }).id;
                // eslint-disable-next-line no-undef
                $scope.article.article_type_fk = _.find($scope.meta.article_type, function (type) {
                    if (type.title === 'Article') {
                        return type.id;
                    }
                }).id;
            }, 2000);
        }
        $scope.saveArticle = function () {
            $scope.article.featured_image = fileUploads.saveImages();
            $scope.article.files = fileUploads.saveFiles();
            if ($scope.article.slug) {
                ArticlesFactory.update($scope.article, function (result) {
                    $khMessage.show('Article has been updated successfully!', 5000);
                    $mdDialog.hide();
                    $rootScope.$broadcast('article_saved');
                }, function (error) {
                });
            } else {
                ArticlesFactory.save($scope.article, function (result) {
                    $khMessage.show('Article has been added successfully!', 5000);
                    $mdDialog.hide();
                    $rootScope.$broadcast('article_saved');
                }, function (error) {
                });
            }
        };
    });
