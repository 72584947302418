(function (angular) {
    'use strict';
    angular.module('ApiUtils.dms.soilType')
        .factory('SoilTypeService', SoilTypeService);

    function SoilTypeService($resource) {
        this.soil_types = $resource('/api/v1.0/fields/soiltype/list/', {}, {}).query();
        return this.soil_types;
    }

})(angular);