(function (angular) {
    "use strict";
    angular.module('ApiUtils.utilities')
        .factory('GenericResourceInterface', GenericResourceInterfaceFactory);

    GenericResourceInterfaceFactory.$inject = ['GenericResource'];

    function GenericResourceInterfaceFactory(GenericResource) {

        function GenericResourceInterface(resource, fields, id_key) {
            this.resource = resource;
            this.fields = fields;
            this.id_key = id_key;
            this.genericResource = new GenericResource();
        }

        GenericResourceInterface.prototype.query = function (submittedParams, successCallback, errorCallback) {
            this.genericResource.retrieveList(this.resource, submittedParams, this.fields, successCallback, errorCallback)
        };

        GenericResourceInterface.prototype.refreshQuery = function (submittedParams, successCallback, errorCallback) {
            this.genericResource.hardRefreshList(this.resource, submittedParams, this.fields, successCallback, errorCallback)
        };

        GenericResourceInterface.prototype.get = function (identifierValue, submittedParams, successCallback, errorCallback) {
            var identifier = this.getObjectIdentifier(identifierValue);
            this.genericResource.retrieveDetail(this.resource, identifier, submittedParams, this.fields, successCallback, errorCallback)
        };

        GenericResourceInterface.prototype.refreshGet = function (identifierValue, submittedParams, successCallback, errorCallback) {
            var identifier = this.getObjectIdentifier(identifierValue);
            this.genericResource.hardRefreshDetail(this.resource, identifier, submittedParams, this.fields, successCallback, errorCallback)
        };

        GenericResourceInterface.prototype.save = function (submittedParams, successCallback, errorCallback) {
            this.genericResource.create(this.resource, submittedParams, successCallback, errorCallback)
        };

        GenericResourceInterface.prototype.update = function (identifierValue, submittedParams, successCallback, errorCallback) {
            var identifier = this.getObjectIdentifier(identifierValue);
            this.genericResource.update(this.resource, identifier, submittedParams, successCallback, errorCallback)
        };

        GenericResourceInterface.prototype.destroy = function (identifierValue, successCallback, errorCallback) {
            var identifier = this.getObjectIdentifier(identifierValue);
            this.genericResource.destroy(this.resource, identifier, successCallback, errorCallback);
        };

        GenericResourceInterface.prototype.delete = function (identifierValue, successCallback, errorCallback) {
            var identifier = this.getObjectIdentifier(identifierValue);
            this.genericResource.delete(this.resource, identifier, successCallback, errorCallback);
        };

        GenericResourceInterface.prototype.wipeCache = function () {
            this.genericResource.wipeCache();
        };

        GenericResourceInterface.prototype.getObjectIdentifier = function (identifierValue) {
            var objIdentifier = {};
            objIdentifier[this.id_key] = identifierValue;
            return objIdentifier;
        };

        return GenericResourceInterface;

    }

})(angular);
