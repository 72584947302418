angular.module('ApiUtils.ksat')
    .factory('SatelliteServiceResource', function ($resource) {
        return $resource('/api/satellite/service/:slug', {}, {
            get: {
                params: {slug: '@slug'}
            },
            query: {
                params: {
                    processing_type: '@processing_type',
                    satellite_data_provider: '@satellite_data_provider',
                    service_name: '@service_name'
                },
                isArray: false
            }
        });
    });
