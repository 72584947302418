angular.module('suggested_links_module').controller("SuggestedLinksAddEditController", ["$scope", "SuggestedLinksFactory", "SubscriptionPackageFactory",
	"$khMessage", "$khDialogClose",
	function ($scope, SuggestedLinksFactory, SubscriptionPackageFactory, $khMessage, $khDialogClose) {
		$scope.add_new = true;
		SubscriptionPackageFactory.query(function (result) {
			$scope.subscription_packages = result;
			setUpForm(result);
		});
		$scope.addnewLink = function (link) {
			SuggestedLinksFactory.save(link, function (result) {
				$scope.link = {};
				$khMessage.show("New link created!");
				$khDialogClose();
			}, function (err) {
			});
		};
		$scope.showExistingLinks = function () {
			$scope.add_new = false;
		};
		SuggestedLinksFactory.query(function (result) {
			$scope.links = result.data.links;
		});

		$scope.editingExternalLink = [];
		$scope.modifyLink = function (tableData) {
			$scope.editingExternalLink[tableData.id] = true;
		};
		$scope.cancelAddingLink = function (tableData) {
			$scope.editingExternalLink[tableData.id] = false;
		};
		$scope.addNew = function () {
			$scope.add_new = true;
		};
		$scope.updateLink = function (link) {
			link["subscription_package"] = link["subscription_package_ids"];
			SuggestedLinksFactory.update(link, function (result) {
				SuggestedLinksFactory.query(function (result) {
					$scope.links = result.data.links;
					$scope.editingExternalLink[link.id] = false;
					$khMessage.show("Link updated!");
				});
			}, function (err) {
			});
		};
		$scope.deleteLink = function (link) {
			SuggestedLinksFactory.delete({slug: link.slug}, function (result) {
				SuggestedLinksFactory.query(function (result) {
					$scope.links = result.data.links;
					$scope.editingExternalLink[link.id] = false;
					$khMessage.show("Deleted successfully!");
				});
			}, function (err) {
			});
		};
		function setUpForm(result) {
			$scope.create_product_link_form = [
				{
					"key": "product_link_name",
					"className": "flex-100",
					"type": "input",
					"templateOptions": {
						"label": "Name",
						required: true
					}
				},
				{
					"key": "product_link_url",
					"className": "flex-100",
					"type": "input",
					"templateOptions": {
						"label": "Link (URL)",
						required: true
					}
				},
				{
					type: "searchable_select",
					key: "subscription_package",
					className: "flex-100",
					templateOptions: {
						label: "Subscription packages",
						multiple: true,
						labelProp: "name",
						valueProp: "id",
						required: true,
						options: result
					}
				}
			];
		}

		$scope.cancel = function () {
			$khDialogClose();
		}

	}]);