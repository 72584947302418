angular.module('ArticlesSummary').controller('SingleArticleSummaryController',
    function ($sce, $scope, $location, $routeParams, ArticlesFactory, ArticlesReportFactory,
              article_slug, $mdDialog, ArticleUtilsService) {
        var RAPIDREPORT = "Rapidtrialreport";
        var MEMBERREPORT = "Memberreport";
        $scope.show_secondary_nav_filters = false;
        ArticlesFactory.get({slug: article_slug}, function (result) {
            $scope.article = result.data;
            getArticlesForFilters({category: $scope.article.category[0]});
            $scope.article.content = $sce.trustAsHtml($scope.article.content);
            ArticleUtilsService.ArticleHelpers().disable_editable_divs();
        });
        $scope.displayOnlyPDF = function (file) {
            if (file) {
                return file.substr(file.lastIndexOf('.') + 1);
            }
        };
        $scope.editArticle = function () {
            $mdDialog.show({
                controller: "ArticleAddEditController",
                templateUrl: '/static/assets/khapps/articles/partials/forms/ArticleAddEditForm.html',
                parent: angular.element(document.body),
                locals: {
                    article_slug: article_slug
                },
                clickOutsideToClose: false
            })
        };
        $scope.showArticle = function (article_slug) {
            mdDialog.show({
                controller: "SingleArticleController",
                templateUrl: '/static/assets/khapps/articles/partials/SingleArticle.html',
                parent: angular.element(document.body),
                locals: {
                    article_slug: article_slug
                },
                clickOutsideToClose: false
            })
        };
        $scope.trustContentAsHtml = function (content) {
            return $sce.trustAsHtml(content);
        };
        // check for permissions
        $scope.checkIfAuthor = function (data) {
            return false;
        };
        // Edit article helper
        $scope.EditArticleHelper = function (slug) {
            return ArticleUtilsService.ArticleHelpers().editArticle($location, slug);
        };

        $scope.trustUrl = function (file) {
            return $sce.trustAsResourceUrl('https://docs.google.com/a/kisanhub/viewer?url=' + file + '&embedded=true');
        };

        $scope.trustDocUrl = function (file) {
            return $sce.trustAsResourceUrl('https://view.officeapps.live.com/op/embed.aspx?src=' + file);
        };

        function equalise() {
            var maxHeight = 0;
            $("div.related-article-card").each(function () {
                if ($(this).height() > maxHeight) {
                    maxHeight = $(this).height();
                }
            });
            $("div.related-article-card-inner").height(maxHeight);
        }

        function getArticlesForFilters(filtersDictionary) {
            ArticlesFactory.query({filters: filtersDictionary}, function (result) {
                $scope.related_articles = result.data;
                if (result.data.length > 0) {
                    setTimeout(equalise, 2000);
                }
            });
        }

        $scope.get_article_edit_link = function () {
            if ($scope.article.article_type == MEMBERREPORT || $scope.article.article_type == RAPIDREPORT) {
                window.open("/dashboard/trial/#/report/" + $scope.article.slug, '_blank');
            } else {
                $location.path("/edit/" + $scope.article.slug)
            }
        };

        $scope.get_client_icon = function () {
            if (tenant_id == 'niab') {
                return '/static/dashboard/client/niab/niab_tag.jpg';
            } else {
                return 'favicon.ico';
            }
        };

        var count_setinterval = 0;

        var set_interval = setInterval(function () {
            if ($scope.article.article_type == RAPIDREPORT) {
                ArticleUtilsService.ArticleHelpers().disable_editable_divs();
                count_setinterval++;
                check_count_of_setinterval();
            }
        }, 5000);

        function check_count_of_setinterval() {
            if (count_setinterval > 2) {
                clearInterval(set_interval);
            }
        }

        $scope.checkForExportAsPdf = function (article) {
            return (article.article_type == MEMBERREPORT || article.article_type == RAPIDREPORT);
        };

        $scope.cancel = function () {
            $mdDialog.hide()
        };
        $scope.showRelatedArticle = function (article_slug) {
            $mdDialog.hide(article_slug);
        };
    });
