angular.module('suggested_links_module').controller("SuggestedLinksListController", ["$scope", "SuggestedLinksFactory", "$mdDialog",
	function ($scope, SuggestedLinksFactory, $mdDialog) {
		SuggestedLinksFactory.query(function (result) {
			$scope.referer_token = result.data.token;
			$scope.product_links = result.data.links;
		});
		$scope.open = function (link) {
			if (link.has_permission) {
				var new_link = link.product_link_url + "?referer=" + $scope.referer_token;
				window.open(new_link, '_blank');
			} else {
				$mdDialog.show({
					controller: "SuggestedLinkViewController",
					templateUrl: '/static/assets/khapps/articles/suggested_links/redirect_link_dialog.html',
					parent: angular.element(document.body),
					clickOutsideToClose: false,
					locals: {
						link: link
					}
				});
			}
		};


	}]);
angular.module('suggested_links_module').controller('SuggestedLinkViewController', function ($scope, link, $mdDialog) {
	$scope.link = link;
	try {
		$scope.tenant_id = tenant_id;
	} catch (err) {
		$scope.tenant_id = '';
	}
	$scope.cancel = function () {
		$mdDialog.hide();
	}
});
