angular.module('articles_setup_module').controller('SubscriptionPackageController', ['$scope', 'SubscriptionPackageFactory', '$khMessage', '$khDialogClose',
    function ($scope, SubscriptionPackageFactory, $khMessage, $khDialogClose) {
        $scope.sub_package_form = [
            {
                'key': 'name',
                'type': 'input',
                'className': 'flex-100',
                'templateOptions': {
                    'label': 'Name',
                    required: true
                }
            }
        ];
        // subs
        $scope.tenant_id = tenant_id;
        $scope.add_new = true;
        $scope.addNew = function () {
            $scope.add_new = true;
        };
        $scope.editingPacakgeData = [];
        $scope.showExistingPackages = function () {
            $scope.add_new = false;
        };
        $scope.subscription_packages = SubscriptionPackageFactory.query(function (result) {
            for (var j = 0; j < result.length; j++) {
                $scope.editingPacakgeData[result[j].id] = false;
            }
        });

        $scope.modifyPackage = function (tableData) {
            $scope.editingPacakgeData[tableData.id] = true;
        };
        $scope.cancelAddingPackage = function (tableData) {
            $scope.editingPacakgeData[tableData.id] = false;
        };
        $scope.updatePackage = function (sub_package) {
            var updated_sub_package = {
                id: sub_package.id,
                name: sub_package.name
            };
            SubscriptionPackageFactory.update(updated_sub_package, function (result) {
                $scope.editingPacakgeData[sub_package.id] = false;
                $khMessage.show('Package has been updated successfully');
            });
        };
        $scope.addnewPackage = function (sub_package) {
            $scope.sub_package = new SubscriptionPackageFactory();
            $scope.sub_package.name = sub_package.name;
            $scope.sub_package.$save(function (result) {
                $scope.newPackage = false;
                $khMessage.show('Package has been added successfully');
                $scope.subscription_packages.unshift(result);
                $khDialogClose();
            }, function (err) {
            });
        };
        $scope.cancel = function () {
            $khDialogClose();
        };

    }]);