(function () {

    'use strict';

    angular.module('ApiUtils.crop.potato', [
        'ApiUtils.crop.potato.dashboard',
        'ApiUtils.crop.potato.yieldDig',
        'ApiUtils.crop.potato.burndown',
        'ApiUtils.crop.potato.admin'
    ])

})();