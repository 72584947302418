(function(angular){
    "use strict";
    angular.module('ApiUtils.dms.genericProducts')
        .factory('GenericSeedBatch', GenericSeedBatchFactory);

    function GenericSeedBatchFactory($resource){
        return $resource('/api/v1.0/genericproduct/genericbatch/seed/:batch_slug/');
    }

})(angular);
