(function (angular) {
    "use strict";
    angular.module('ApiUtils.crop.potato.yieldDig')
        .factory('PotatoYieldDigAssessmentResults', PotatoYieldDigAssessmentResultsFactory);

    PotatoYieldDigAssessmentResultsFactory.$inject = ['$resource'];

    function PotatoYieldDigAssessmentResultsFactory($resource) {
        return $resource('api/v1.0/potato/yield-dig/results/:yield_dig_slug/', {
            yield_dig_slug: '@yield_dig_slug'
        }, {
            query: {
                isArray: true,
                method: 'GET'
            },
            get: {
                method: 'GET'
            },
            save: {
                method: 'POST'
            },
            update: {
                method: 'PUT'
            },
            delete: {
                method: 'DELETE'
            }
        });
    }

})(angular);